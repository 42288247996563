@import "../../assets/variables/color.scss";

.button {
  width: calc(100% - 32px);
  padding: 14px 0;
  background-color: $accent;
  color: $text;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 24px;
  text-align: center;
  transition: background-color 0.4s;

  outline: none;
  border: 0;

  margin: 24px 16px;

  &[disabled] {
    background-color: $accent-diasable;

    &:hover {
      background-color: $accent-diasable;
      cursor: default;
    }
  }
}

.button:hover,
.button:active {
  transition: background-color 0.4s;
  background-color: $accent-hover;
  cursor: pointer;
}
