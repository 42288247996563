@import "./assets/variables/color.scss";

.wrapper {
  @media screen and (min-width: 575px) {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 800px, cover;
    background-repeat: no-repeat, no-repeat;
    background-position: 50%, top;
    background-image: url(./assets/images/phone.png),
      url(./assets/images/bg.jpg);
  }

  @media (min-width: 575px) and (max-height: 880px) {
    background-size: 645px, cover;
  }

  @media (min-width: 575px) and (min-height: 1080px) {
    background-size: 880px, cover;
  }
}

.phone {
  @media screen and (min-width: 575px) {
    background-color: black;
    position: relative;
    width: 395px;
    height: 838px;
    border-radius: 24px;
    overflow-y: scroll;
  }

  @media (min-width: 575px) and (max-height: 880px) {
    width: 330px;
    height: 675px;
  }

  @media (min-width: 575px) and (min-height: 1080px) {
    width: 435px;
    height: 920px;
  }
}

// poster

.posterContainer {
  position: fixed;
  width: 100%;
  height: 50vh;

  @media screen and (min-width: 575px) {
    width: 395px;
    height: 400px;
    border-radius: 24px;
  }

  @media (min-width: 575px) and (max-height: 880px) {
    width: 330px;
  }

  @media (min-width: 575px) and (min-height: 1080px) {
    height: 430px;
    width: 435px;
  }
}

.optionsContainer {
  position: absolute;
  bottom: 0;
  background-color: $white;
  width: 100%;
  max-width: 575px;
  height: 55vh;
  border-radius: 24px 24px 0 0;
  padding: 24px 0;

  @media screen and (min-width: 575px) {
    width: 395px;
    height: 470px;
    border-radius: 24px;
  }

  @media (min-width: 575px) and (max-height: 880px) {
    width: 330px;
    height: 400px;
  }

  @media (min-width: 575px) and (min-height: 1080px) {
    width: 435px;
    height: 520px;
  }
}

.optionsContainer::before {
  content: "";
  position: absolute;
  width: 32px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s;

  @media screen and (min-width: 575px) {
    display: none;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;

  @media screen and (min-width: 575px) {
    border-radius: 24px 24px 0 0;
  }
}

// question
.topBarContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

// options
.optionsWrapper {
  margin-top: 16px;
}

.onScrollOption::before {
  background-color: rgba(0, 0, 0, 0.6);
  top: 8px;
  transition: all 0.2s;
}

.header {
  padding: 0 16px;
}

.contentWrapper {
  margin: 0 16px;
}

.bgWhite {
  background-color: $white;
}
