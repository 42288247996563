.paragraph {
  padding-top: 16px;
  font-size: 14px;
  line-height: 24px;
  a{
    font-size: 14px;
    line-height: 24px;
  }
}

.strong {
  font-weight: 600;
}
