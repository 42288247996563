@import "../../assets/variables/color.scss";

.title {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: $text;
}

.text {
  display: block;
  font-size: 14px;
  line-height: 24px;
}

.optionsItem {
  width: 100%;
  text-align: left;
  padding: 16px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid $gray-light;
  display: block;
  background-color: transparent;
}

.optionsItem[disabled] > span {
  color: $gray-dark;
}

.optionsItemTrue,
.optionsItemFalse {
  & > .title {
    color: $text !important;
    font-weight: 600;
  }

  & > .text {
    color: $text !important;
  }
}

.optionsItemTrue {
  background-color: $success;
  border-color: $success;
}

.optionsItemFalse {
  background-color: $error;
  border-color: $error;
}
