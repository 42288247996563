$text: #212529;
$white: #ffffff;
$white-bg: #f0f0f0;
$gray-dark: #7e7e7e;
$gray-light: #e3e3e3;
$accent: #ffdd21;
$accent-hover: #f3bc09;
$accent-diasable: #fff5bd;
$success: #c8e6c9;
$error: #ffcdd2;
